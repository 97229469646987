import React from 'react'
import { StaticQuery, graphql } from "gatsby"

const TestimonialSingle = () => (
  <StaticQuery
      query={graphql`
      {
        allWpPage(filter: {slug: {eq: "services"}}) {
          nodes {
            servicePage {
              testimonial {
                testimonialContent
                image {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    sizes {
                      sourceUrl
                    }
                  }
                }
                name
                designation
              }
            }
          }
        }
        }
      `}
      render={data => {
      const testimonialContent = data.allWpPage.nodes[0].servicePage.testimonial[0];
      
      return (
        <div className="testimonial-single">
          <h3 className="h3"><span>“</span>{testimonialContent.testimonialContent}</h3>
          <div className="text-end">
          <div className="signature">{testimonialContent.name}</div>
          </div>

          <div className="testimonial-avatar justify-content-end d-flex align-items-center">
            {/* <img loading="lazy" width="185" height="185" src={testimonialContent.image && testimonialContent.image.mediaItemUrl} alt={testimonialContent.image && testimonialContent.image.altText} className="img-fluid gatsby-image-wrapper" /> */}
            <picture>
              {/* <source media="(min-width:1800px)" srcSet={pageContent?.featuredImage.node?.mediaItemUrl} /> */}
              {/* <source media="(min-width:1280px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[4]?.sourceUrl} /> */}
              {/* <source media="(min-width:992px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[1]?.sourceUrl} /> */}
              {/* <source media="(min-width:575px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[3]?.sourceUrl} /> */}
              <img loading="lazy" width="150" height="150" src={testimonialContent?.image?.mediaDetails.sizes[1]?.sourceUrl} alt={testimonialContent?.image?.altText} className="d-block gatsby-image-wrapper" />
            </picture>

            <div className="ms-md-4 ms-3">
                <p>{testimonialContent.name}</p>
                <h6 className="mb-0">{testimonialContent.designation}</h6>
            </div>
          </div>            
        </div>
      );
    }}></StaticQuery>
  );

export default TestimonialSingle;
