import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import LinksSlider from "./linksSlider"
import FooterLinks from "./footerLinks";
import SocialSite from "./socialSite";
// import FooterLogo from '../images/Alkye-logo.svg';

const Footer = () => (
  <StaticQuery
  query={graphql`
    {
      allWpPage(filter: {slug: {eq: "global-page"}}) {
        nodes {
          globalPage {
            footerLogo {
              altText
              mediaItemUrl
            }
            readyToWorkTitle
            startAProjectTitle
            socialsHeader {
              name
              url
            }
            contactDetails {
              contactDetail
            }
          }
        }
      }
      wpMenu(slug: {eq: "footer-menu"}) {
        menuItems {
          nodes {
            id
            label
            path
          }
        }
      }
    }
  `}
  render={data => {
    const pageContent = data.allWpPage.nodes[0].globalPage;
    const linkMenu = data.wpMenu.menuItems.nodes;
    return(
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <p>{pageContent?.readyToWorkTitle}</p>
              <Link aria-label="contact" to="/contact" className="h1">{pageContent?.startAProjectTitle} 
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                  </svg>
                </span>
              </Link>

              <div className="footer-links flex-wrap d-flex align-items-center justify-content-between">
                {pageContent?.contactDetails?.map((link, index) => (
                  <div key={index} dangerouslySetInnerHTML={{__html: link.contactDetail}} />
                ))}
                {/* <a href="/">hello@alkye.com</a> */}
              </div>
            </div>
          </div>
          <div className="footer-middle">
            <div className="container">
              <LinksSlider menu={linkMenu} />
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
            <div className="row align-items-center mb-sm-4">
                <div className="col-md-5 mb-md-0 mb-3 col-lg-6">
                  <Link aria-label="Footer Logo" to="/">
                    {/* pageContent?.footerLogo.mediaItemUrl  | pageContent?.footerLogo.altText */}
                    <img loading="lazy" width="220" height="100" style={{maxWidth: '220px'}} src={'/Alkye-logo.svg'} alt={'Site logo'} className="gatsby-image-wrapper" />
                  </Link>
                </div>
                <div className="col-md-7 col-lg-6">
                  <div className="header-social d-flex align-items-center justify-content-between">
                    <SocialSite socialLinks={pageContent?.socialsHeader} />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <ul className="list-unstyled d-flex align-items-center flex-wrap mb-md-0">
                  <FooterLinks />
                </ul>

                <p className="mb-0">Copyright {new Date().getFullYear()} Alkye Services Pty Ltd. All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
    )
  }}
  ></StaticQuery>
  );

export default Footer
