import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Marquee from "react-fast-marquee";

const makeStrength = () => (
    <StaticQuery
        query={graphql`
        {
            allWpPage(filter: {slug: {eq: "services"}}) {
                nodes {
                    servicePage {
                        makeDataYourStrengthTitle
                        makeDataYourStrengthSubtitle
                        makeDataYourStrengthLogoRow1 {
                            logo {
                                altText
                                mediaItemUrl
                            }
                        }
                        makeDataYourStrengthLogoRow2 {
                            logo {
                                altText
                                mediaItemUrl
                            }
                        }
                        makeDataYourStrengthLogoRow3 {
                            logo {
                                altText
                                mediaItemUrl
                            }
                        }
                    }
                }
            }
        }
    `}
    render={data => {
    const pageContent = data.allWpPage.nodes[0].servicePage;   
    return (
    <>
        <div className="container">
            <div className=" pt-md-5 pt-4">
                <div className="row border-0 mb-md-5 mb-4 pb-xl-4 justify-content-center text-center">
                    <div className="col-sm-9 col-md-7 col-lg-6 col-xl-7 col-xxl-8" data-aos="fade-up" data-aos-delay="200">
                        <div className="section-title px-xl-4">
                        <h2 className='h2' dangerouslySetInnerHTML={{__html: pageContent?.makeDataYourStrengthTitle}} />
                        <p className='lead-lg'>{pageContent?.makeDataYourStrengthSubtitle}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="rectangle-logos overflow-hidden" data-aos="fade-up" data-aos-delay="200">
                <Marquee children={null} gradient={false}>
                    <div className='w-100'>
                        {pageContent?.makeDataYourStrengthLogoRow1.length > 0 && (
                            <ul className="rectangle-logo rectangle-logo-odd d-flex align-items-center">
                                {pageContent?.makeDataYourStrengthLogoRow1 && pageContent?.makeDataYourStrengthLogoRow1.map((logo, index) => (
                                    <li key={index * 15}>
                                        {/* <img loading="lazy" src={logo.logo && logo.logo.mediaItemUrl} alt={logo.logo && logo.logo.altText} className="img-fluid gatsby-image-wrapper" /> */}
                                        <picture>
                                            {/* <source media="(min-width:1800px)" srcSet={pageContent?.featuredImage.node?.mediaItemUrl} /> */}
                                            {/* <source media="(min-width:1280px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[4]?.sourceUrl} /> */}
                                            {/* <source media="(min-width:992px)" srcSet={pageContent?.servicePage.bannerBelowCircleImage?.mediaItemUrl} /> */}
                                            {/* <source media="(min-width:480px)" srcSet={pageContent?.servicePage.bannerBelowCircleImage?.mediaDetails.sizes[2]?.sourceUrl} /> */}
                                            <img loading="lazy" width="190" height="60" src={logo.logo?.mediaItemUrl} alt={logo.logo?.altText} className="img-fluid gatsby-image-wrapper" />
                                        </picture>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {pageContent?.makeDataYourStrengthLogoRow2.length > 0 && (
                            <ul className="rectangle-logo rectangle-logo-even d-flex align-items-center">
                                {pageContent?.makeDataYourStrengthLogoRow2 && pageContent?.makeDataYourStrengthLogoRow2.map((logo, index) => (
                                    <li key={index * 35}>
                                        <img loading="lazy" width="190" height="60" src={logo.logo && logo.logo.mediaItemUrl} alt={logo.logo && logo.logo.altText} className="img-fluid gatsby-image-wrapper" />
                                    </li>
                                ))}
                            </ul>
                        )}

                        {pageContent?.makeDataYourStrengthLogoRow3.length > 0 && (
                            <ul className="rectangle-logo rectangle-logo-odd d-flex align-items-center">
                                {pageContent?.makeDataYourStrengthLogoRow3 && pageContent?.makeDataYourStrengthLogoRow3.map((logo, index) => (
                                    <li key={index * 55}>
                                        <img loading="lazy" width="190" height="60" src={logo.logo && logo.logo.mediaItemUrl} alt={logo.logo && logo.logo.altText} className="img-fluid gatsby-image-wrapper" />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>


                </Marquee>
            </div>
    </>
 );
}}></StaticQuery>
);

export default makeStrength;