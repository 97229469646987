import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MakeStrength from "../components/makeStrength"
import TestimonialSingle from "../components/testimonialSingle"
import ServicesList from "../components/servicesList"

const ServicesPage = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  return(
    <Layout HeadClass="header-inner">
      <Seo title={pageContent?.seo.title} image={pageContent?.featuredImage && pageContent?.featuredImage.node.mediaItemUrl} description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />
      
      <div className="media-banner overflow-hidden text-white position-relative">
        {/* <img loading="lazy" src={pageContent?.featuredImage && pageContent?.featuredImage.node.mediaItemUrl} alt={pageContent?.featuredImage && pageContent?.featuredImage.node.altText} className="w-100 media-banner-parallax d-block gatsby-image-wrapper" /> */}
        <picture>
          <source media="(min-width:1800px)" srcSet={pageContent?.featuredImage.node?.mediaItemUrl} />
          <source media="(min-width:1280px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[4]?.sourceUrl} />
          <source media="(min-width:992px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[1]?.sourceUrl} />
          <source media="(min-width:575px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[3]?.sourceUrl} />
          <img loading="lazy" width="1920" height="1080" src={pageContent?.featuredImage.node?.mediaDetails.sizes[0]?.sourceUrl} alt={pageContent?.featuredImage.node?.altText} className="w-100 d-block gatsby-image-wrapper" />
        </picture>
        
        <div className="container position-absolute">
          <div className="row">
            <div className="col" data-aos="fade-up">
              <h1 className="title position-relative mt-md-0 mt-5 mb-0" dangerouslySetInnerHTML={{__html: pageContent?.servicePage.bannerTitle}} />
            </div>
          </div>
        </div>
      </div>

      <div className="py-120 service-info">
        <div className="container px-xl-5" data-aos="fade-up" dangerouslySetInnerHTML={{__html: pageContent?.content}} />
      </div>

      <div className="py-120 pt-5 text-center">
        <div className="container" data-aos="zoom-in">
          {/* <img loading="lazy" src={pageContent?.servicePage.bannerBelowCircleImage.mediaItemUrl} alt={pageContent?.servicePage.bannerBelowCircleImage.altText} className="img-fluid gatsby-image-wrapper" /> */}
          <picture>
            {/* <source media="(min-width:1800px)" srcSet={pageContent?.featuredImage.node?.mediaItemUrl} /> */}
            {/* <source media="(min-width:1280px)" srcSet={pageContent?.featuredImage.node?.mediaDetails.sizes[4]?.sourceUrl} /> */}
            <source media="(min-width:992px)" srcSet={pageContent?.servicePage.bannerBelowCircleImage?.mediaItemUrl} />
            <source media="(min-width:480px)" srcSet={pageContent?.servicePage.bannerBelowCircleImage?.mediaDetails.sizes[2]?.sourceUrl} />
            <img loading="lazy" width="911" height="911" src={pageContent?.servicePage.bannerBelowCircleImage?.mediaDetails.sizes[0]?.sourceUrl} alt={pageContent?.servicePage.bannerBelowCircleImage?.altText} className="img-fluid gatsby-image-wrapper" />
          </picture>
        </div>
      </div>

      <div className="py-120">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-11 col-sm-10 col-md-9 col-lg-7 col-xxl-8" data-aos="fade-up" data-aos-delay="200">
              <TestimonialSingle />
            </div>
          </div>
        </div>
      </div>

      <div className="services py-5">
        <ServicesList />
      </div>

      <MakeStrength />
    
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "services"}}) {
    nodes {
      seo {
        title
        metaDesc
        focuskw
      }
      content
      featuredImage {
        node {
          altText
          mediaItemUrl
          mediaDetails {
            sizes {
              sourceUrl
            }
          }
        }
      }
      servicePage {
        bannerTitle
        bannerBelowCircleImage {
          altText
          mediaItemUrl
          mediaDetails {
            sizes {
              sourceUrl
            }
          }
        }
        makeDataYourStrengthTitle
        makeDataYourStrengthSubtitle
      }
    }
  }
}`