/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AOS from 'aos';

import Header from "./header"
import Footer from "./footer"

import 'react-phone-input-2/lib/style.css'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import 'aos/dist/aos.css';
import "./css/bootstrap.min.css"
import "./css/style.css"
import "./css/responsive.css"
import "./css/ads.css"
import "./fonts.css"

const Layout = ({ HeadClass, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if(typeof document !== "undefined") {
    AOS.init({
      once: true,
      // offset: 100,
      duration: 500,
      easing: 'ease'
    });
    AOS.refresh();
  }
  
  return (
    <div id="wrapper">
      <Header HeadClass={HeadClass} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="page-content">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
