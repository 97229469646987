import React from 'react'
import { Link, StaticQuery, graphql } from "gatsby"

const servicesList = () => (
  <StaticQuery
    query={graphql`
    {
      allWpService(sort: {fields: date, order: DESC}) {
        nodes {
          title
          excerpt
          slug
          service {
            serviceMainPageListContent
            serviceMainPageSpecifications {
              name
            }
          }
        }
      }
      }
    `}
    render={data => {
    const servicePost = data.allWpService.nodes;
    console.log(servicePost);
    return (
    <div className="container">
        {servicePost && servicePost.map((item) => (
          <div className="row" data-aos="fade-up" data-aos-delay="200" key={item.slug}>
            <div className="col-md-3 col-lg-4 mb-md-0 mb-3">
              <h2><Link aria-label={item.slug} to={'/services/'+item.slug+'/'} dangerouslySetInnerHTML={{__html: item.title}} /></h2>
            </div>
            <div className="col-md-9 col-lg-8">
              {/* <div dangerouslySetInnerHTML={{__html: item.excerpt}} />
              <p style={{padding: '0px'}}></p> */}
              <p className='lead-lg'>{item.service.serviceMainPageListContent}</p>
              {item.service.serviceMainPageSpecifications && item.service.serviceMainPageSpecifications.length > 0 && (
                <ul className="list-unstyled mb-4 p-0">
                  {item.service.serviceMainPageSpecifications && item.service.serviceMainPageSpecifications.map((item, index) => (
                    <li key={index} dangerouslySetInnerHTML={{__html: item.name}} />
                  ))}
                </ul>
              )}
              <div className='pt-md-4 pt-2'>
                <Link aria-label={item.slug} to={'/services/'+item.slug+'/'} className="btn text-uppercase btn-link d-inline-flex align-items-center">
                  READ More

                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        ))}        
      </div>
    );
  }}></StaticQuery>
);

export default servicesList;